/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable max-len */
import React from "react";
import { connect } from "react-redux";

import { Container } from "../../../../components/core/03-base/Container";
import { LoadingIndications, LoadingIndicator } from "../../../../components/core/05-atoms/LoadingIndicator";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { ReduxState, isRemoteLoading } from "../../../../store/ReduxState";
import { FormRegisterDRAObjectObject, FormRegisterDRAObjectObjectResponse, FormRegisterDRARequest} from "../../../../store/DowntimeRegistrations/Types";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../store/RemoteActions";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { ThemePalette, ThemePaletteState, ThemeShadowSizes } from "../../../../theme/_Types";
import * as I18n from "../../../../utils/translations/I18n";
import { Blockquote } from "../../../../components/core/05-atoms/Blockquote";
import { RTE } from "../../../../components/core/07-organisms/RTE";
import { isDisabled } from "../00-blocks/Form/utils";
import { Summary } from "../00-blocks/Form/Summary";
import { DRAForm } from "../00-blocks/Form";

type State = FormRegisterDRAObjectObject;

interface OwnProps {
    asPage: boolean;
    id?: string;
}


interface StateProps {
    defaultResults?: FormRegisterDRAObjectObjectResponse;
    formResults?: FormRegisterDRAObjectObjectResponse;
    loading: boolean;
}

/**
 *
 */
interface DispatchProps {
    getDefaultValues: (id: string) => void;
    form: (id: string, body: FormRegisterDRARequest) => void;
    clearResults: () => void;
    clear: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    defaultResults: s.prop("remote").prop(RemoteScope.GET_ID_DRA),
    formResults: s.prop("remote").prop(RemoteScope.REGISTER_DRA),
    loading: isRemoteLoading(s, RemoteScope.REGISTER_DRA) || isRemoteLoading(s, RemoteScope.GET_ID_DRA),
});

/**
 *
 */
const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    getDefaultValues: (id) =>
        dispatch(remoteTrigger(RemoteScope.GET_ID_DRA, {id: id})),
    form: (id, body) =>
        dispatch(remoteTrigger(RemoteScope.REGISTER_DRA, {id, body})),
    clearResults: () => (
        (
            dispatch(remoteClearResponse(RemoteScope.REGISTER_DRA)),
            dispatch(remoteClearError(RemoteScope.REGISTER_DRA))
        )),
    clear: () => (
        dispatch(remoteClearResponse(RemoteScope.GET_ID_DRA)),
        dispatch(remoteClearError(RemoteScope.GET_ID_DRA))
    ),
});

class RegisterDRAComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
        };
        this.clearLocalState = this.clearLocalState.bind(this);
    }

    public componentDidMount() {
        if (!!this.props.id) {
            this.props.getDefaultValues(this.props.id);
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if ((this.props.defaultResults?.content?.id !== prevProps.defaultResults?.content?.id) && !!this.props.defaultResults?.content?.id) {
            this.setState({
                ...this.props.defaultResults?.content,
            }, );
        }
    }

    public render() {
        return (
            <>
                <Container
                    theme={{
                        shadow: this.props.asPage ? ThemeShadowSizes.SMALL : undefined,
                        palette: this.props.asPage ? ThemePalette.CONTRAST_PRIMARY : undefined,
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    {(
                        this.props.loading ? (
                            <LoadingIndicator
                                type={LoadingIndications.DEFAULT}
                                theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                            />) : (<>
                            {this.showHeader()}
                            {this.showError()}
                            {this.showForm() ? (
                                <DRAForm
                                    form={this.state}
                                    onChange={(v) => this.setState(v)}
                                    loading={this.props.loading}
                                    clear={this.clearLocalState}
                                    onSubmit={this.props.form}
                                />
                            ) : this.hasSuccess() ? this.showSummary() : this.errorReturn()
                            }
                        </>)
                    )}
                </Container>
            </>
        );
    }

    private clearLocalState() {
        this.props.clear();
        if (!!this.props.id) {
            this.props.getDefaultValues(this.props.id);
        }
    }

    private showError() {
        if (this.hasErrorOrInvalid() && this.props.defaultResults?.error?.message !== "Downtime already recorded."
            && this.props.defaultResults?.error?.message !== "Invalid or expired hash.") {
            return (
                <>
                    <Blockquote theme={{paletteState: ThemePaletteState.DANGER}}>
                        <RTE>
                            {(
                                <>
                                    <h4>
                                        {this.props.formResults?.error?.message}
                                    </h4>
                                    <br/>
                                </>
                            )}
                            {
                                this.props.formResults?.error?.validations?.map((v, index) => (
                                    // v.property ? `${v.property ?? "-"}: ${v.constraints?.required ?? ""}` : ""
                                    <span key={index}>
                                        <b>{v.property}:</b>
                                        { Object.keys(v.value).map((key, index) => (
                                            <>
                                                <p key={index}>
                                                    {key}: {v.value[key]}
                                                </p>
                                                <br/>
                                            </>
                                        ))}
                                    </span>
                                ))
                            }
                        </RTE>
                    </Blockquote>
                    <br/>
                </>);
        }
        return null;
    }

    private showSummary() {
        if (!this.props.formResults?.content) {
            return null;
        }
        return (<Summary results={this.props.formResults}/>);
    }

    private hasErrorOrInvalid() {
        return this.props.formResults?.error?.message === "Validation failed." || this.hasError();
    }

    private hasSuccess() {
        return this.props.formResults?.statusCode === 200;
    }

    private hasBeenSubmitted(){
        return this.props.formResults?.content?.recorded === true ||
        this.props.defaultResults?.error?.message === "Downtime already recorded.";
    }

    private hasError() {
        return this.props.formResults?.statusCode === 500 || this.props.formResults?.statusCode === 400 ||  this.props.formResults?.statusCode === 404 || this.props.formResults?.statusCode === 403 ||
            (!this.props.id || (this.state.id !== this.props.id));
    }

    private errorReturn(){
        if(this.hasBeenSubmitted()){
            return (
                (
                    <Blockquote
                        theme={{
                            paletteState: ThemePaletteState.DANGER,
                        }}
                    >
                        Deze werkzaamheden zijn al verwerkt.
                    </Blockquote>
                )
            );
        } else if(!this.props.id || (this.state.id !== this.props.id)){
            return (
                (
                    <Blockquote
                        theme={{
                            paletteState: ThemePaletteState.DANGER,
                        }}
                    >
                        <b> {I18n.getText(
                            "mary.09-views.02-pages.error.title",
                            "Oops, something went wrong!")}</b><br/> Wij kunnen geen gegevens ophalen.
                    </Blockquote>
                )
            );
        }
        return null;
    }

    private showForm() {
        if((this.state.id || this.props.id) === undefined){
            return false;
        }
        if(this.hasBeenSubmitted()){
            return false;
        }
        return (!this.props.formResults && this.props.defaultResults?.statusCode === 200) || this.props.formResults?.error?.code === 400;
        // return !this.props.formResults && this.state.id === this.props.id || this.props.formResults?.error?.code === 400;
    }

    private showHeader() {
        return this.hasSuccess() ? (
            <Header
                title="Werkzaamheden afgemeld"
                description={
                    "Bedankt voor de afmelding."
                }
            />
        ) : this.hasError() ?
            <Header
                title="Afmelden werkzaamheden"
            /> :
            <Header
                title={"Afmelden werkzaamheden"}
                description={
                    "Met onderstaand formulier kunt u de werkzaamheden afmelden."
                }
            />;
    }
}

export const RegisterDRA = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RegisterDRAComp);

export const testUtil = {
    isDisabled: isDisabled,
};
