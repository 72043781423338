/* eslint-disable max-len */
export const I18nMaryTranslationsNL = {
    "mary.cancel": "Annuleren",
    "mary.yes": "Ja",
    "mary.logout": "Uitloggen",
    "mary.download": "Downloaden",
    "mary.close": "Sluiten",
    "mary.05-atoms.licenseplateinput.placeholder": "CLD-01-X",
    "mary.05-atoms.required.text": "Verplichte velden.",
    "mary.06-molecules.tablebody.no-results": "Geen resultaten om weer te geven.",
    "mary.06-molecules.env-indicator-bar-prefix": "Dit is de",
    "mary.06-molecules.env-indicator-bar-postfix": "omgeving.",
    "mary.07-organisms.form.field.utils.date--error": "De datumnotatie is niet correct.",
    "mary.07-organisms.form.field.utils.exceed-value--error": "De opgegeven waarde is boven het limiet ({{max}}).",
    "mary.07-organisms.form.field.utils.exceed-length--error": "Max. ({{max}}) karakters overschreden.",
    "mary.07-organisms.form.field.utils.meet-password-length--error":
        "Wachtwoord moet een lengte hebben tussen ({{min}}) en ({{max}}) karakters.",
    "mary.07-organisms.form.field.utils.meet-password--error":
        "Het wachtwoord moet minstens 1 nummer, 1 hoofdletter, 1 normale letter en 1 speciale letter bevatten.",
    "mary.07-organisms.form.field.utils.has-license-plate--error":
        "Het opgegeven kenteken is niet valide.",
    "mary.07-organisms.form.field.utils.has-email--error":
        "De opgegeven e-mail is niet valide.",
    "mary.07-organisms.dragndroparea.title--touch": "Klik & Selecteer",
    "mary.07-organisms.dragndroparea.description--touch": "En upload jouw bestanden.",
    "mary.07-organisms.dragndroparea.title": "Drag & Drop",
    "mary.07-organisms.dragndroparea.description": "Of klik om bestanden te selecteren.",
    "mary.07-organisms.formbar.required": "Zorg dat alle verplichte velden zijn ingevuld.",
    "mary.09-views.00-blocks.comingsoon.title": "Binnekort beschikbaar",
    "mary.09-views.00-blocks.loadingdata.is-loading": "Aan het laden..",
    "mary.09-views.00-blocks.loadingdata.title--init": "Hebben wij al gezocht?",
    "mary.09-views.00-blocks.loadingdata.description--init":"Claude stelt voor om de filter aan te passen..",
    "mary.09-views.00-blocks.loadingdata.title": "Geen resultaten!",
    "mary.09-views.00-blocks.loadingdata.description": "Claude denkt dat er geen data is om weer te geven.",
    "mary.09-views.02-pages.loading.description": "Content aan het laden..",
    "mary.09-views.02-pages.nomatchingroute.title": "Verdwaald? (404)",
    "mary.09-views.02-pages.nomatchingroute.description": "Claude, is dat zeker.",
    "mary.09-views.02-pages.error.title": "Oeps, er is iets misgegaan!",
    "mary.09-views.02-pages.error.content-title": "Er heeft zich een technische fout plaatsgevonden",
    "mary.09-views.02-pages.error.content-description": "Verzend de onderstaande informatie naar team Claude van VWPFS.",
    "mary.09-views.02-pages.error.content-fallback-message":
        "Claude is niet in staat om technische informatie te verzamelen, dit zou zomaar een serieus probleem kunnen zijn! :(",
    "mary.09-views.02-pages.error.send-btn": "Verzend informatie",
    "mary.09-views.03-modals.accountsettings.tabs.changepassword--success": "Uw wachtwoord is succesvol gewijzigd.",
    "mary.09-views.03-modals.accountsettings.tabs.changepassword-old-password-label": "Oud wachtwoord",
    "mary.09-views.03-modals.accountsettings.tabs.changepassword-new-password-label": "Nieuw wachtwoord",
    "mary.09-views.03-modals.accountsettings.tabs.changepassword-new-password--valid": "Wachtwoord opgegeven.",
    "mary.09-views.03-modals.accountsettings.tabs.changepassword-new-password-submit-btn": "Wijzig",
    "mary.09-views.03-modals.accountsettings.tabs.general-user-name-label": "Gebruikersnaam",
    "mary.09-views.03-modals.accountsettings.tabs.general-role-label": "Rol",
    "mary.09-views.03-modals.accountsettings.tabs.general-information-title": "Informatie",
    "mary.09-views.03-modals.accountsettings.tabs.general-session-title": "Sessie",
    "mary.09-views.03-modals.accountsettings.tabs.logoutbutton-label": "Uitloggen",
    "mary.09-views.03-modals.accountsettings.tabs.logoutbutton-all-devices-label": "Log uit op alle apparaten",
    "mary.09-views.03-modals.accountsettings.tabs.changepassword.title": "Wachtwoord wijzigen",
    "mary.09-views.03-modals.accountsettings.tabs.general.title": "Algemeen",
    "mary.09-views.03-modals.accountsettings.title": "Account instellingen",
    "mary.09-views.03-modals.notification.title": "Waarschuwing",
    "mary.09-views.03-modals.notification.message-label": "Bericht:",
    "mary.09-views.03-modals.notification.message-fallback": "Geen bericht.",
    "mary.09-views.03-modals.logout.title": "Weet u het zeker?",
    "mary.09-views.03-modals.logout.description": `Als u doorgaat bent u uitgelogd en moet u opnieuw
                         inloggen bij de volgende keer dat u de website bezoekt.`,
    "mary.09-views.refresh-btn": "Ververs pagina",
    "mary.09-views.continue-btn": "Ga verder",
    "mary.utils.document.title": "Applicatie door Claude.",
    "mary.utils.formvalidations.date--error": "Datum is niet geldig.",
    "mary.utils.formvalidations.date-to--error": "De geselecteerde datum mag niet voor de vanaf datum plaatsvinden.",
    "mary.utils.formvalidations.date-to--success": "Tot datum opgegeven.",
    "mary.utils.formvalidations.date-from--error": "De geselecteerde datum mag niet na de tot datum plaatsvinden.",
    "mary.utils.formvalidations.date-from--success": "Vanaf datum opgegeven.",
    "mary.utils.verifycontext.browser-support": "Browser onderstuning",
    "mary.utils.verifycontext.newversion-title": "Melding",
    "mary.utils.verifycontext.newversion-description": "Nieuwe versie beschikbaar!",
    "mary.utils.verifycontext.browsersupport-description":
            `Wij ondersteunen deze browser niet meer vanwege veiligheids- en gebruiksvriendelijkheids redenen,\n \n
            Gebruik alstublieft een modernere browser zoals Chrome, Edge, Firefox of Safari.`,
    "mary.utils.verifycontext.newversionavailable-message":
        "Er is een nieuwe versie ({{version}}) van {{app-name}} beschikbaar. U zou moeten verversen om de laatste wijzigingen te krijgen.",
    "mary.utils.verifycontext.version-message":
                    "{{app-name}} door Claude is up-to-date met de laatste versie: v{{version}}",
    "mary.utils.whichenv.int": "Integratie",
    "mary.utils.whichenv.cons": "Consolidatie",
    "mary.utils.whichenv.dev": "Development",
    "mary.utils.whichenv.local": "Lokaal",
    "mary.utils.whichenv.prod": "Productie",
};


export const I18nAmplifyAuthTranslationsNL = {
    "Sign in": "Inloggen",
    "Sign up": "Aanmelden",
    "Sign out": "Uitloggen",
    "Sign In": "Inloggen",
    "Sign Up": "Aanmelden",
    "Sign Out": "Uitloggen",
    "Create account": "Account aanmaken",
    "Create Account": "Account aanmaken",
    "No account? ": "Geen account? ",
    "Forgot Password": "Wachtwoord vergeten?",
    "Username": "Gebruikersnaam",
    "Password": "Wachtwoord",
    "Change Password": "Wijzig wachtwoord",
    "New Password": "Nieuw wachtwoord",
    "Email": "E-mail",
    "Change": "Wijzig",
    "Sign in to your account": "Inloggen",
    "Submit": "Verzenden",
    "Resend Code": "Opnieuw verzenden",
    "Have an account? ": "Heeft u een account? ",
    "Create a new account": "Maak een nieuw account",
    "Code cannot be empty": "Code moet ingevuld zijn",
    "Enter your password": " ",
    "Enter your username": " ",
    "Forgot your password? ": "Wachtwoord vergeten? ",
    "Reset password": "Vraag een nieuw wachtwoord aan.",
    "Reset your password": "Vraag een nieuw wachtwoord aan.",
    "Send Code": "Verzend code",
    "Back to Sign In": "Terug naar inloggen",
    "Invalid verification code provided, please try again.": "Ongeldige verificatie code, probeer het opnieuw.",
    "Invalid code provided, please request a code again.": "Ongeldige verificatie code, vraag een nieuwe code aan.",
    "Phone Number": "Telefoonnummer",
    "Confirm": "Bevestig",
    "Password cannot be empty": "Wachtwoord moet ingevuld zijn",
    "Attempt limit exceeded, please try after some time.":
        "U heeft het maximaal aantal pogingen bereikt, probeer later opnieuw.",
    "Password attempts exceeded": "U heeft het maximaal aantal inlogpogingen bereikt." +
        " Na 30 minuten kunt u het nogmaals proberen." +
        " Weet u uw wachtwoord niet meer, dan kunt u deze opnieuw aanvragen.",
    "User does not exist": "Uw gebruikersnaam wordt niet herkend. Controleer uw gebruikersnaam.",
    "Incorrect username or password": "Gebruikersnaam of wachtwoord is ongeldig",
    "Username cannot be empty": "Gebruikersnaam moet ingevuld zijn",
    "null failed with error Generate callenges lambda cannot be called..": "Wachtwoord moet ingevuld zijn",
    "Confirm a Code": "Bevestig een code",
    "Confirm Sign In": "Bevestig inloggen",
    "Confirm Sign Up": "Bevestig aanmelding",
    "Resend a Code": "Verzend code opnieuw",
    "Skip": "Sla over",
    "Verify": "Bevestig",
    "Verify Contact": "Bevestig contact",
    "Code": "Code",
    "Account recovery requires verified contact information":
        "Het herstellen van het account vereist geverifieerde contact informatie",
    "User already exists": "Gebruiker bestaat al",
    "Invalid password format": "Wachtwoord voldoet niet aan de eisen",
    "Invalid phone number format": "Telefoonnummer voldoet niet aan de eisen",
    "Username/client id combination not found.": "Gebruikersnaam is niet bekend",
    "Temporary password has expired and must be reset by an administrator.":
        "Tijdelijk wachtwoord is verlopen en moet opnieuw worden aangevraagd door een administrator",
    // FIXME: this is such an ugly hack, we have to create our own Claude. ID. Because there are too many bugs.
    // tslint:disable-next-line:max-line-length
    "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+": "Gebruikersnaam is ongeldig",
};
